<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <div>
      <b-row>
        <b-col
          md="4"
        >
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between mb-1 ">
                <div>
                  <h2>{{ total_pending }}</h2>
                  <p class="fontsize-sm m-0">
                    Pending Sales Order(s)
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-warning">
                  <i class="ri-inbox-fill" />
                </div>

              </div>
              <router-link
                :to="viewMore('Pending')"
                class="d-flex justify-content-end"
              >
                View More
                <i
                  class="ri-arrow-right-s-line"
                  style="margin-top:1px"
                />
              </router-link>
            </template>
          </iq-card>

        </b-col>
        <b-col md="4">

          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between mb-1">
                <div>
                  <h2>{{ total_rejected }}</h2>
                  <p class="fontsize-sm m-0">
                    Rejected Sales Order(s)
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-danger">
                  <i class="ri-file-excel-line" />
                </div>
              </div>
              <router-link
                :to="viewMore('Rejected')"
                class="d-flex justify-content-end"
              >
                View More
                <i
                  class="ri-arrow-right-s-line"
                  style="margin-top:1px"
                />
              </router-link>
            </template>
          </iq-card>
        </b-col>
        <b-col md="4">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between mb-1">
                <div>
                  <h2>{{ total_delivered }}</h2>
                  <p class="fontsize-sm m-0">
                    Sales Order Total Delivered
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-success">
                  <i class="ri-truck-line" />
                </div>
              </div>
              <router-link
                :to="viewMore('Delivered')"
                class="d-flex justify-content-end"
              >
                View More
                <i
                  class="ri-arrow-right-s-line"
                  style="margin-top:1px"
                />
              </router-link>
            </template>
          </iq-card>

        </b-col>

      </b-row>

      <b-row class="mt-3">
        <b-col

          md="4"
        >
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between mb-1">
                <div>
                  <h2>{{ total_close }}</h2>
                  <p class="fontsize-sm m-0">
                    Closed Sales Order(s)
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-primary">
                  <i class="ri-shut-down-line" />
                </div>
              </div>
              <router-link
                :to="viewMore('close_so')"
                class="d-flex justify-content-end"
              >
                View More
                <i
                  class="ri-arrow-right-s-line"
                  style="margin-top:1px"
                />
              </router-link>
            </template>
          </iq-card>

        </b-col>
        <b-col

          md="4"
        >
          <iq-card>
            <template v-slot:body>
              <div
                class="d-flex d-flex align-items-center justify-content-between mb-1"
                style="padding-bottom:26.2px"
              >
                <div>
                  <h2>{{ total_sales }}</h2>
                  <p class="fontsize-sm m-0">
                    Total Sales
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-success">
                  <i class=" ri-money-dollar-box-fill" />
                </div>
              </div>
            </template>
          </iq-card>

        </b-col>
      </b-row>

      <b-card-group deck />
    </div>

    <br>

  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'
import { SharedDashboardService } from '@/services'

export default {
  name: 'UserDashboard',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      total_pending: 0,
      total_delivered: 0,
      total_rejected: 0,
      total_sales: 0,
      total_close: 0
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  mounted () {
    core.index()
    this.getDashboardDetails()
  },

  methods: {
    async getDashboardDetails () {
      const { data } = await SharedDashboardService.get()
      this.total_pending = data?.total_pending
      this.total_delivered = data?.total_delivered
      this.total_rejected = data?.total_rejected
      this.total_close = data?.total_close
      this.total_sales = data?.total_sales.toLocaleString('en-US')
    },

    viewMore (status) {
      return {
        path: `/${this.user?.role}/sales-order-tracker/${status}`
      }
    }
  }
}
</script>
